import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2';
import Footer from '../layout/footer4';
import { getBanners, getEvents, getNewsAndArticles, getTVC } from '../server';
import { useState } from 'react';
import moment from 'moment';
import BlogSlider1 from '../element/blogSlider1';
import { useMemo } from 'react';

const bg = require('../../images/banner/bnr1.jpg');
const numOfElements = 6;
function TVC() {
  const [tvc, setTvc] = useState(null);
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getBanners({ type: 'news & media', subType: 'television commercials' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getTVC()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setTvc(res.data);
          setTotalPages(Math.ceil(res.data.length / numOfElements));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const paginatedData = useMemo(() => {
    return tvc?.slice(
      (currentPage - 1) * numOfElements,
      currentPage * numOfElements
    );
  }, [tvc, currentPage]);
  return (
    <>
      <Header />
      {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://hr.sweetitech.co.uk/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Television Commercials</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={'./'}>Home</Link>
                    </li>
                    <li>Television Commercials</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-area">
            <div className="container">
              <div
                id="masonry"
                className="dlab-blog-grid-2"
                style={{ width: '100%' }}
              >
                <div className="row">
                  {paginatedData &&
                    paginatedData.map((item, index) => (
                      <div className="post card-container col-lg-4 col-md-6 col-sm-12">
                        <iframe
                          width="380"
                          height="400"
                          src={item?.link}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title={item?.title}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="pagination-bx clearfix  col-md-12 text-center">
                <ul className="pagination">
                  <li className="previous">
                    <Link
                      to={'#'}
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                        }
                      }}
                    >
                      <i className="ti-arrow-left"></i> Prev
                    </Link>
                  </li>
                  {[...Array(totalPages)].map((item, index) => (
                    <li className={currentPage === index + 1 ? 'active' : ''}>
                      <Link to={'#'} onClick={() => setCurrentPage(index + 1)}>
                        {index + 1}
                      </Link>
                    </li>
                  ))}

                  <li className="next">
                    <Link
                      to={'#'}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      Next <i className="ti-arrow-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="section-full content-inner bg-white wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.6s"
              >
                <div className="container">
                  <div className="section-head text-black text-center">
                    <h2 className="title">News & Articles</h2>
                    <p></p>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <BlogSlider1 data={news} imgEffect="rotate" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default TVC;
