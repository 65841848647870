import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2';
import Footer from '../layout/footer4';
import ClientSlider1 from '../element/clientSlider1';
import { useEffect } from 'react';
import { getAllDirectors, getBanners, getNewsAndArticles } from '../server';
import { useState } from 'react';
import BlogSlider1 from '../element/blogSlider1';
const bg = require('../../images/banner/bnr4.jpg');

function Team1() {
  const [directors, setDirectors] = useState(null);
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'about us', subType: 'board of directors' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllDirectors({ type: 'board of directors' })
      .then((res) => {
        console.log('directors', res);
        if (res) {
          setDirectors(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://hr.sweetitech.co.uk/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Board Of Directors</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Board Of Directors</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="section-full content-inner">
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">Board Of Directors</h2>
                  <p></p>
                </div>
                <div
                  className="row"
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  {directors &&
                    directors.map((item, index) => (
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="dlab-box m-b30 dlab-team1">
                          <div className="dlab-media">
                            <Link to="#">
                              <img
                                width="358"
                                height="460"
                                alt=""
                                src={`https://hr.sweetitech.co.uk/abc/${item?.image?.url}`}
                              />
                            </Link>
                          </div>
                          <div className="dlab-info">
                            <h4 className="dlab-title">
                              <Link to="#">{item?.name}</Link>
                            </h4>
                            <span
                              className="dlab-position"
                              style={{ paddingBottom: '6vh' }}
                            >
                              {item?.designation}
                            </span>

                            {/* <ul className="dlab-social-icon dez-border">
                              <li>
                                <Link className="fa fa-facebook" to="#"></Link>
                              </li>
                              <li>
                                <Link className="fa fa-twitter" to="#"></Link>
                              </li>
                              <li>
                                <Link className="fa fa-linkedin" to="#"></Link>
                              </li>
                              <li>
                                <Link className="fa fa-pinterest" to="#"></Link>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div
              className="section-full content-inner bg-white wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">News & Articles</h2>
                  <p></p>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <BlogSlider1 data={news} imgEffect="rotate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default Team1;
