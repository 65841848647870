import React, { Component, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2';
import Footer from '../layout/footer4';
import { getEvents, getNewsAndArticles } from '../server';
import { useState } from 'react';
import moment from 'moment';
import ReadMore from './readmore';

const bg = require('../../images/banner/bnr1.jpg');
const numOfElements = 6;
function News() {
  const [events, setEvents] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setEvents(res.data);
          setTotalPages(Math.ceil(res.data.length / numOfElements));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const paginatedData = useMemo(() => {
    return events?.slice(
      (currentPage - 1) * numOfElements,
      currentPage * numOfElements
    );
  }, [events, currentPage]);
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: 'url(' + bg + ')' }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">News & Articles</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li>News & Articles</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-area">
          <div className="container">
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{ width: '100%' }}
            >
              <div className="row">
                {paginatedData &&
                  paginatedData.map((item, index) => (
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <div className="blog-post blog-grid blog-rounded blog-effect1">
                        <div className="dlab-post-media dlab-img-effect">
                          <Link to={'#'}>
                            <img
                              src={`https://hr.sweetitech.co.uk/abc/${item.image.url}`}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="dlab-info p-a25 border-1">
                          <div className="dlab-post-meta ">
                            <ul>
                              <li className="post-date">
                                <strong>
                                  {moment(item.date).format('MMM Do YY')}
                                </strong>
                              </li>
                              <li className="post-author">
                                By <Link to={'#'}>{item.author}</Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dlab-post-title ">
                            <h4 className="post-title">
                              <Link to={'#'}>{item.title}</Link>
                            </h4>
                          </div>
                          <div className="dlab-post-text">
                            {item?.description && (
                              <ReadMore text={item.description} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="pagination-bx clearfix  col-md-12 text-center">
              <ul className="pagination">
                <li className="previous">
                  <Link
                    to={'#'}
                    onClick={() => {
                      if (currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                      }
                    }}
                  >
                    <i className="ti-arrow-left"></i> Prev
                  </Link>
                </li>
                {[...Array(totalPages)].map((item, index) => (
                  <li className={currentPage === index + 1 ? 'active' : ''}>
                    <Link to={'#'} onClick={() => setCurrentPage(index + 1)}>
                      {index + 1}
                    </Link>
                  </li>
                ))}

                <li className="next">
                  <Link
                    to={'#'}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next <i className="ti-arrow-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default News;
