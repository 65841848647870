import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2';
import Footer from '../layout/footer4';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  getAllAbout,
  getBanners,
  getDocuments,
  getHRPolicy,
  getJobs,
  getNewsAndArticles,
  getQualityAssurance,
  getServices,
  getSustainability,
} from '../server';
import BlogSlider1 from '../element/blogSlider1';
import moment from 'moment';
import { saveAs } from 'file-saver';

var bnr3 = require('./../../images/banner/bnr3.jpg');

function Brochure() {
  const [policy, setPolicy] = useState(null);
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'news & media', subType: 'brochure' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getDocuments({ type: 'brochure' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setPolicy(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDownload = (link) => {
    console.log('link', link);
    saveAs(`https://hr.sweetitech.co.uk/abc/${link}`, 'Brochure.pdf');
  };

  return (
    <>
      <Header />

      {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://hr.sweetitech.co.uk/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Brochure</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>Brochure</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block">
            {policy &&
              policy.map((item, index) => (
                <div className="section-full content-inner bg-white">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 m-b30">
                        <div className="our-story">
                          {/* <span>Our Products</span> */}
                          <h2 className="title">{item?.title}</h2>

                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <p style={{ marginLeft: '10px' }}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${item?.description}`,
                                }}
                              />
                            </p>
                          </p>
                          <button
                            className="site-button m-r15"
                            type="button"
                            onClick={() => handleDownload(item?.docs[0].url)}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 m-b30 ">
                        {/* <img
                      src={`https://hr.sweetitech.co.uk/abc/${item?.image.url}`}
                      className="radius-sm"
                      alt=""
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div
              className="section-full content-inner bg-white wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">News & Articles</h2>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <BlogSlider1 data={news} imgEffect="rotate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default Brochure;
