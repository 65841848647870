import React, { Component } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer4 from '../layout/footer4';
import Header2 from '../layout/header2';
import { cvUpload, fileUpload, getBanners } from '../server';

const bg = require('../../images/banner/bnr5.jpg');

function OnlineCv() {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const [photo, setPhoto] = useState(null);
  const [cv, setCV] = useState(null);

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'career', subType: 'create online CV' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submitFrom = async (data) => {
    console.log('data', data);
    let cvId;
    let photoId;
    if (data?.cv[0] && data?.photo[0]) {
      cvId = await fileUpload(data?.cv[0], 'PDF');
      photoId = await fileUpload(data?.photo[0], 'IMAGE');
      console.log('cvId', cvId?.data?.id, 'photoId', photoId?.data?.id);
      if (cvId?.data?.id && photoId?.data?.id) {
        const submittingdata = {
          fullName: data?.fullname,
          email: data?.email,
          mobileNumber: data?.mobile,
          jobsName: data?.jobName,
          cvId: cvId?.data?.id,
          photoId: photoId?.data?.id,
        };
        console.log('submittingdata', submittingdata);
        if (submittingdata) {
          cvUpload(submittingdata)
            .then((res) => {
              Swal.fire({
                title: 'Success',
                text: 'Your CV has been submitted successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
              resetField('fullname');
              resetField('email');
              resetField('mobile');
              resetField('jobName');
              resetField('photo');
              resetField('cv');
            })
            .catch((err) => {
              console.log('err', err);
            });
        }
      }
    }
  };
  const handleReset = () => {
    resetField('fullname');
    resetField('email');
    resetField('mobile');
    resetField('jobName');
    resetField('photo');
    resetField('cv');
  };

  return (
    <>
      <Header2 />
      {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://hr.sweetitech.co.uk/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Online CV</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Online CV</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-full content-inner shop-account">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="font-weight-700 m-t0 m-b40">
                    CREATE ONLINE CV
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 m-b30">
                  <div className="p-a30 border-1  m-auto">
                    <div className="tab-content">
                      <form
                        id="login"
                        className="tab-pane active"
                        onSubmit={handleSubmit(submitFrom)}
                      >
                        <h4 className="font-weight-700">
                          PERSONAL INFORMATION
                        </h4>

                        <div className="form-group">
                          <label className="font-weight-700">Full Name *</label>
                          <input
                            required
                            className="form-control"
                            placeholder="Full Name"
                            type="text"
                            {...register('fullname')}
                          />
                        </div>

                        <div className="row form-group">
                          <div className="col">
                            <label className="font-weight-700">
                              Email Address*
                            </label>
                            <input
                              type="email"
                              required
                              className="form-control"
                              placeholder="Email"
                              {...register('email')}
                            />
                          </div>
                          <div className="col">
                            <label className="font-weight-700">
                              Mobile Number*
                            </label>
                            <input
                              type="number"
                              required
                              className="form-control"
                              placeholder="Mobile Number"
                              {...register('mobile')}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">Jobs Name *</label>
                          <input
                            required
                            className="form-control "
                            placeholder="Jobs Name"
                            type="tex"
                            {...register('jobName')}
                          />
                        </div>
                        <div className="row form-group">
                          <div className="col">
                            <label className="font-weight-700">
                              Upload Photo*
                            </label>
                            <input
                              type="file"
                              required
                              className="form-control-file"
                              id="exampleFormControlFile1"
                              {...register('photo')}
                            />
                          </div>
                          <div className="col">
                            <label className="font-weight-700">
                              Upload CV*
                            </label>
                            <input
                              type="file"
                              required
                              className="form-control-file"
                              id="exampleFormControlFile2"
                              {...register('cv')}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div className="text-left m-t15">
                            <button
                              className="site-button button-lg radius-no outline outline-2"
                              onClick={handleReset}
                            >
                              RESET FORM
                            </button>
                          </div>
                          <div className="text-right m-t15">
                            <button
                              className="site-button button-lg radius-no outline outline-2"
                              type="submit"
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer4 />
    </>
  );
}
export default OnlineCv;
