import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2';
import Footer from '../layout/footer4';
// Sliders
import Testimonial1 from './../element/testimonial1';
import BlogSlider1 from '../element/blogSlider1';
import {
  blogContent3,
  projectContent1,
  homeSliderContent3,
} from '../element/SliderContent';
import ProjectSlider1 from '../element/projectSlider1';
import HomeSlider from '../element/homeslider1';
import ScrollToTop from '../element/scrollToTop';
import Slider from 'react-slick';

import {
  getAllAbout,
  getAllDirectors,
  getAllProducts,
  getEvents,
  getHomeSlider,
  getNewsAndArticles,
} from '../server';
import TeamSlider3 from '../element/teamSlider3';

const work1 = require('../../images/our-work/pic1.jpg');
const work2 = require('../../images/our-work/pic2.jpg');
const work3 = require('../../images/our-work/pic3.jpg');
const work4 = require('../../images/our-work/pic4.jpg');

// import '../../css/skin/skin-2.min.css';

const bg = require('../../images/background/map-bg.png');

function Index3() {
  const [homeSlider, setHomeSlider] = useState(null);
  const [aboutUs, setAboutUs] = useState(null);
  const [directors, setDirectors] = useState(null);
  const [products, setProducts] = useState(null);
  const [news, setNews] = useState(null);
  const [events, setEvents] = useState(null);

  useEffect(() => {
    getHomeSlider()
      .then((res) => {
        console.log('res', res);
        if (res) {
          setHomeSlider(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllAbout({ type: 'home_page' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setAboutUs(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllDirectors({ type: 'board of directors' })
      .then((res) => {
        console.log('directors', res);
        if (res) {
          setDirectors(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllProducts()
      .then((res) => {
        console.log('products', res);
        if (res) {
          setProducts(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getEvents()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setEvents(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log('news', news);
  }, [news]);

  return (
    <>
      <Header />

     <HomeSlider
        data={
          homeSlider && homeSlider.length > 0 ? homeSlider : homeSliderContent3
        }
        contentWrapperCls="sliderStyle4"
        hideBtn
      />
  

      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full content-inner bg-white">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 col-md-6 m-b30 wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="our-story">
                    <span>About Us</span>
                    {/* <h2 className="title">Commited to High <br /> Quality <span className="text-primary">  Service </span></h2> */}
                    <h2 className="title">{aboutUs?.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${aboutUs?.body.slice(0, 300)}...`,
                      }}
                    />

                    <Link to="/about-us" className="site-button btnhover14">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 m-b30 wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <img
                    src={`https://hr.sweetitech.co.uk/abc/${aboutUs?.image?.url}`}
                    className="radius-sm"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="section-full content-inner-2 bg-gray wow fadeInUp"
            data-wow-duration="2s"
            data-wow-delay="0.4s"
          >
            <div className="container">
              <div className="section-head text-black text-center">
                <h2 className="title">Our Products</h2>
                <p></p>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ProjectSlider1 data={products} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="section-full content-inner bg-white wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="container">
              <div className="section-head text-black text-center">
                <h2 className="title">News & Articles</h2>
                <p></p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <BlogSlider1 data={news} imgEffect="rotate" />
                </div>
              </div>
            </div>
          </div>

          <div className="section-full text-center bg-white content-inner-2">
            <div className="container">
              <div className="section-head text-black text-center">
                <h2 className="title">Board Of Directors</h2>
                <p></p>
              </div>
              <div
                className="custom-about"
                style={{
                  width: '75%',
                  marginLeft: '15%',
                }}
              >
                <TeamSlider3 team={directors} />
              </div>
            </div>
          </div>

          <div
            className="section-full content-inner-2 bg-gray wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="container">
              <div className="section-head text-black text-center">
                <h2 className="title">Events & Publications</h2>
              </div>
              <div className="bg-white"></div>
              <Testimonial1 data={events} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop className="style1 radius white" />
    </>
  );
}
export default Index3;
