import React, { Component, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2';
import Footer from '../layout/footer4';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  getAllAbout,
  getBanners,
  getNewsAndArticles,
  getQualityAssurance,
} from '../server';
import BlogSlider1 from '../element/blogSlider1';
import moment from 'moment';
import Lightbox from 'yet-another-react-lightbox';
import '../../../node_modules/yet-another-react-lightbox/dist/styles.css';
import Fullscreen from '../../../node_modules/yet-another-react-lightbox/dist/plugins/fullscreen';

var bnr3 = require('./../../images/banner/bnr3.jpg');
const numOfElements = 6;

function Certification() {
  const [qualityTest, setQualityTest] = useState(null);
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imagesToshow, setImagesToshow] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    getBanners({ type: 'products & services', subType: 'certifications' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getQualityAssurance({ type: 'certifications' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setQualityTest(res?.data);
          setTotalPages(Math.ceil(res.data.length / numOfElements));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const paginatedData = useMemo(() => {
    return qualityTest?.slice(
      (currentPage - 1) * numOfElements,
      currentPage * numOfElements
    );
  }, [qualityTest, currentPage]);
  function simpleGallery(images) {
    setImagesToshow(images);
    setIsOpen(true);
  }
  const imageList = useMemo(() => {
    return imagesToshow?.map((item, index) => {
      return {
        src: `https://hr.sweetitech.co.uk/abc/${item?.url}`,
        width: 1024,
        height: 768,
      };
    });
  }, [imagesToshow]);

  return (
    <>
      {modalIsOpen == false ? <Header /> : null}

      {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://hr.sweetitech.co.uk/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Certifications</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>Certifications</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-area">
            <div className="container">
              <div
                id="masonry"
                className="dlab-blog-grid-2"
                style={{ width: '100%' }}
              >
                <div className="row">
                  {paginatedData &&
                    paginatedData.map((item, index) => (
                      <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                        <div className="dlab-box blog-grid-2">
                          <div className="dlab-media dlab-img-effect zoom-slow">
                            <img
                              src={`https://hr.sweetitech.co.uk/abc/${item?.images[0]?.url}`}
                              alt=""
                              onClick={() => {
                                simpleGallery(item.images);
                              }}
                            />
                          </div>
                          <div className="dlab-info">
                            <div className="dlab-post-title ">
                              <h4 className="post-title">
                                <Link to={'#'}>{item?.title}</Link>
                              </h4>
                            </div>
                            <div className="dlab-post-meta">
                              <ul>
                                <li className="post-date">
                                  <i className="fa fa-calendar"></i>
                                  <strong>
                                    {moment(item?.images[0]?.created_at).format(
                                      'DD MMM YYYY'
                                    )}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <Lightbox
                  plugins={[Fullscreen]}
                  open={modalIsOpen}
                  close={() => setIsOpen(false)}
                  slides={imageList}
                />
              </div>
              <div className="pagination-bx clearfix  col-md-12 text-center">
                <ul className="pagination">
                  <li className="previous">
                    <Link
                      to={'#'}
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                        }
                      }}
                    >
                      <i className="ti-arrow-left"></i> Prev
                    </Link>
                  </li>
                  {[...Array(totalPages)].map((item, index) => (
                    <li className={currentPage === index + 1 ? 'active' : ''}>
                      <Link to={'#'} onClick={() => setCurrentPage(index + 1)}>
                        {index + 1}
                      </Link>
                    </li>
                  ))}

                  <li className="next">
                    <Link
                      to={'#'}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      Next <i className="ti-arrow-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="section-full content-inner bg-white wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.6s"
              >
                <div className="container">
                  <div className="section-head text-black text-center">
                    <h2 className="title">News & Articles</h2>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <BlogSlider1 data={news} imgEffect="rotate" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default Certification;
